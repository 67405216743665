<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <div>
    <loading :active.sync="isLoading"
             :can-cancel="false"
             :is-full-page="fullPage"></loading>
    <div class="row justify-content-md-center">

      <div class="col-12">
        <filter-side-bar>
          <template slot="ContentSideBar">
            <!-- Filtros -->
            <h5 class="mb-0">Filtrar por:</h5>
            <form id="report-goals-list-filter" v-on:submit.prevent="applyFilter()">

              <!-- Período -->
              <h6 class="mb-3 mt-5">{{ $t('kpi.volume.period') }}</h6>
              <div class="row">
              </div>
              <div class="py-1 text-center">
                <div class="md-form mb-0">
                  <div class="row">
                    <div class="col-6">
                      <button type="submit" class="btn btn-primary btn-fill btn-block mr-2 ">{{ $t('filter.apply') }}</button>
                    </div>
                    <div class="col-6">
                      <button type="button"  v-on:click="resetFilter" class="btn btn-cancel btn-fill btn-block mr-2 ">{{ $t('filter.reset') }}</button>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </template>
        </filter-side-bar>
      </div>

      <!-- Bloco com os Filtros -->
      <div class="col-12 mb-3 filter-container">
        <filter-container :activeFilterList="getActiveFilterList" />
      </div>

      <!-- Quantidade cadastrada - por vendedor responsável -->
      <div class="col-12 col-md-12 report-content mt-0">
        <div class="card">
          <div class="card-body">
            <h5 class="box-title mb-3">{{ $t('report.tariff_indicators.quantity-sent-by-seller') }}</h5>

            <!-- Table -->
            <div class="table-responsive ">
              <vuetable ref="vuetable"
                :noDataTemplate="this.$i18n.t('noting to result')"
                :api-url="quantitySentBySellerEndpoint"
                :fields="vQuantitySentBySellerTableFields"
                :sort-order="quantitySentBySellerSortOrder"
                :css="css.table"
                pagination-path="pagination"
                :per-page="20"
                :multi-sort="multiSort"
                :append-params="filter"
                multi-sort-key="shift"
                :http-options="httpOptions"
                track-by="id"
                @vuetable:pagination-data="onPaginationData"
                @vuetable:loading="onLoading"
                @vuetable:loaded="onLoaded"
                @vuetable:load-error="handleLoadError"
                @vuetable:cell-clicked="onRowClicked">
              </vuetable>
            </div>
            <vuetable-pagination ref="pagination"
              :css="css.pagination"
              @vuetable-pagination:change-page="onChangePage">
            </vuetable-pagination><!-- END Table -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// eslint-disable-next-line
import { util } from '@/utils/utils.js'

// Components
import Vuetable, { VuetablePagination } from 'vuetable-2'
import FilterSideBar from '@/components/FilterSideBar.vue'
import FilterContainer from '@/components/FilterContainer'

// Loading
import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'

// DatePicker
// eslint-disable-next-line
import '@/assets/vendor/bootstrap-datepicker/dist/js/bootstrap-datepicker.min.js'
// eslint-disable-next-line
import '@/assets/vendor/bootstrap-datepicker/dist/locales/bootstrap-datepicker.pt-BR.min.js'
import '@/assets/vendor/bootstrap-datepicker/dist/css/bootstrap-datepicker3.min.css'

export default {
  name: 'TariffScheduleUsageSentBySeller',
  metaInfo () {
    return {
      titleTemplate: this.$i18n.t('report.title') + ' ' + this.$i18n.t('report.tariff_indicators.title') + ' - %s'
    }
  },
  data () {
    return {
      reportData: {
        data: []
      },
      quantitySentBySellerEndpoint: process.env.VUE_APP_APIENDPOINT + 'v2/task/tariffs/quantitysentbyseller',
      tempPeriod: 'monthly',
      isLoading: true,
      fullPage: true,
      quantitySentBySellerTable: [
        {
          name: 'vendedor',
          title: 'Vendedor',
          sortField: 'p.nome',
          formatter: (value) => this.$util.formatEmpty(value, undefined, ' - ')
        },
        {
          name: 'ativos',
          title: 'Ativos',
          sortField: 'SUM(CASE WHEN u.status = 1 then 1 ELSE 0 END)',
          formatter: (value) => this.$util.formatEmpty(value, undefined, ' - ')
        },
        {
          name: 'inativos',
          title: 'Inativos',
          sortField: 'SUM(CASE WHEN u.status != 1 then 1 ELSE 0 END)',
          formatter: (value) => this.$util.formatEmpty(value, undefined, ' - ')
        }
      ],
      quantitySentBySellerSortOrder: [
        { field: 'p.nome', direction: 'asc' }
      ],
      activeFilterList: {
        usuario: null,
        vendedor: null
      },
      firstFilter: true,
      filter: {},
      multiSort: true,
      httpOptions: {
        headers: {
          Authorization:
            'Bearer ' + global.instanceApp.$Cookie.get('_userAuth_')
        }
      },
      css: {
        table: {
          tableClass: 'table table-hover align-middle table-clickable',
          loadingClass: 'loading',
          ascendingIcon: 'ti-angle-double-up',
          descendingIcon: 'ti-angle-double-down',
          handleIcon: 'glyphicon glyphicon-menu-hamburger'
        },
        pagination: {
          infoClass: 'pull-left',
          wrapperClass: 'vuetable-pagination text-center',
          activeClass: 'page-item active',
          disabledClass: 'disabled',
          pageClass: 'btn btn-border',
          linkClass: 'btn btn-border',
          icons: {
            first: '',
            prev: '',
            next: '',
            last: ''
          }
        }
      }
    }
  },
  components: {
    Loading,
    FilterSideBar,
    FilterContainer,
    Vuetable,
    VuetablePagination
  },
  beforeMount () {
    this.isLoading = true
    /**
     * Returns the week number for this date.  dowOffset is the day of week the week
     * "starts" on for your locale - it can be from 0 to 6. If dowOffset is 1 (Monday),
     * the week returned is the ISO 8601 week number.
     * @param int dowOffset
     * @return int
     */
    // eslint-disable-next-line
    this.filters.filCliente = []
    this.buildFilters()
  },
  mounted () {
    this.$store.dispatch('SetShowFilter', false)
    this.$store.dispatch('setShowSearch', false)
  },
  methods: {
    buildFilters () {
      this.$store.dispatch('setAppliedFilter', this.filters)
    },
    // Filter
    applyFilter () {
      this.buildFilters()

      this.$store.dispatch('setAppliedFilter', this.filters)

      this.hideQuickSidebar()
      this.$refs.vuetable.refresh()
    },
    resetFilter (event) {
      this.hideQuickSidebar()

      this.$store.dispatch('setAppliedFilter', this.filters)

      this.buildFilters()
    },
    hideQuickSidebar () {
      const sideBar = document.querySelector('#quick-sidebar')
      document.body.classList.remove('has-backdrop')
      sideBar.classList.remove('shined')
    },
    onPaginationData (paginationData) {
      this.$refs.pagination.setPaginationData(paginationData)
    },
    onChangePage (page) {
      this.$refs.vuetable.changePage(page)
    },
    onRowClicked (row) {
    },
    onLoading () {
      this.isLoading = true
    },
    onLoaded () {
      this.isLoading = false
    },
    handleLoadError (response) {
    }
  },
  computed: {
    getActiveFilterList () {
      let activeFilterList = {
        // usuario: null,
        // vendedor: null
      }

      return activeFilterList
    },
    vQuantitySentBySellerTableFields () {
      return this.quantitySentBySellerTable
    },
    user () {
      return this.$store.getters.userInfo || null
    },
    filters () {
      if (this.$Cookie.get('_userStoredFilters_')) {
        return JSON.parse(this.$Cookie.get('_userStoredFilters_'))
      } else {
        return null
      }
    },
    refreshFilter () {
      return this.$store.getters.getRefreshFilter || null
    }
  },
  watch: {
    refreshFilter: function () {
      if (this.refreshFilter) {
        this.applyFilter()
        this.$store.dispatch('setRefreshFilter', false)
      }
    }
  }
}
</script>

<style>
  .card-body {
    padding: 0.5rem 1.8rem;
  }

  @media (max-width: 576px) {

    .card-body {
      padding: 0.8rem 0.2rem;
    }

    .gray-box {
      padding: 0.8rem 0.2rem;
    }
  }

  .dark-gray-box {
    background-color: #e3e2e1;
    border-radius: 0 0 38px 38px;
    margin-bottom: 10px;
    padding: 25px;
    padding-left: 75px;
    padding-right: 75px;
    padding-bottom: 1px;
  }

  .dark-gray-box .line {
    margin-top: 10px;
    text-align: center;
    margin-bottom: 27px;
  }

  .label {
    padding-left: 47px;
    padding-top: 7px;
    padding-bottom: 7px;
    background-position: center left;
    background-repeat: no-repeat;
    margin-left: 10px;
    font-size: 11px;
    font-weight: bold;

  }

  .label.air {
    background-image: url("/static/images/report/air_icon.png");
  }

  .label.ship {
    background-image: url("/static/images/report/ship_icon.png");
  }

  .label.lcl {
    background-image: url("/static/images/report/lcl_icon.png");
  }

  .label.fcl {
    background-image: url("/static/images/report/fcl_icon.png");
  }

  .label.insurance {
    background-image: url("/static/images/report/insurance_icon.png");
  }

  .label.green {
    background-image: url("/static/images/report/green_icon.png");
    padding-left: 20px;
  }

  .label.yellow {
    background-image: url("/static/images/report/yellow_icon.png");
    padding-left: 20px;
  }

  .label.red {
    background-image: url("/static/images/report/red_icon.png");
    padding-left: 20px;
  }

  .card-legend, .card-title {
    color: #84754E;
    text-align: center;
    font-size: 18px;
  }

  .card-title, .card-title b {
    margin-top: 45px;
    margin-bottom: 0;
    font-size: 19px;

  }

  .summary-box .message{
    font-weight: 200;
    font-size: 14px;
    font-family: pluto_sanscond_light;
  }

  .gray-box {
    background-color: #F3F1ED;
    border-radius: 38px 38px 0 0;
    margin-top: 82px;
  }

  /* Sellect Section */
  .row.seller-box {
    margin-bottom: -100px;
  }

  .seller {
    text-align: center;
    top: -100px;

  }

  .seller img {
    border: 10px solid #F3F1ED;
    border-radius: 148px;
    width: 148px;
    position: relative;
    overflow: hidden;
  }

  .seller h2 {
    margin: 12px 0;
    font-size: 25px;
  }

  .summary-box {
    margin-top: 39px;
  }

  /* Message box */
  .message-box {
    margin: 0 2.8rem;
    color: #84734C;
    font-size: 14px;
  }

  @media (max-width: 576px) {
    .message-box {
      margin: 0 0.8rem;
    }
  }

  /* Details */
  .detail-box .indicator-box {
    background-color: #fefefe;
    border-radius: 0 0 40px 40px;
    padding: 50px 60px;
  }

  /* Details */
  .detail-box .content-box {
    background-color: #fefefe;
    border-radius: 0 0 40px 40px;
    padding: 20px 20px;
  }
  .detail-box .content-box .card-title {
    margin-top: 15px;
  }

  .detail-box.first .indicator-box {

    border-radius: 40px 40px 0 0;
    padding: 0 60px;
    padding-top: 50px;
  }

  .detail-box.middle .indicator-box {

    border-radius: 0 0 0 0;
    padding: 0 60px;
    padding-bottom: 65px;
  }

  .detail-box.first {
    margin-top: 70px;

  }
  .detail-box.last .indicator-box, .detail-box.simple .indicator-box {
    padding-bottom: 45px;

  }

  .detail-box.simple {
    margin-top: 70px;
  }

  .detail-box.simple .indicator-box {
    border-radius: 40px 40px 40px 40px;
  }

  /* Activities */

  .activities h3 {
    margin-top: 0;
    color: #84754E;
    text-align: center;
    font-size: 15px;
    font-weight: bold;
  }

  .activities h3.message {
    margin-top: 45px;
    font-size: 19px;
    font-weight: bold;
  }

  .activities .card-title {
    text-align: center;
    font-size: 13px !important;
    font-weight: bold;
    color: #84754E;
  }

  .activities .icon {

    background-repeat: no-repeat;
    background-position: center center;
    width: 100%;
    height: 55px;
  }

  .activities .icon.price {
    background-image: url("/static/images/report/price_icon.png");
  }

  .activities .icon.visits {
    background-image: url("/static/images/report/visits_icon.png");
  }

  .activities .icon.email {
    background-image: url("/static/images/report/email_icon.png");
  }

  .activities .icon.presentation {
    background-image: url("/static/images/report/presentations_icon.png");
  }

  .activities .icon.callPhones {
    background-image: url("/static/images/report/cellPhone_icon.png");
  }

  /* right operation / modal label */
  .detail-box .col-md-2 {
    padding: 0;
  }

  .detail-box .col-md-2 p {
    top: 113px;
    font-size: 26px;
    font-weight: 200;
    color: #84754E;
  }

  .detail-box .col-md-2 p {
    top: 197px;
    left: 0;
    padding-left: 58px;
    padding-top: 18px;
    padding-bottom: 18px;
    font-size: 23px;
    font-weight: 200;
    position: relative;
    background-image: url("/static/images/report/carret.png");
    background-position: -9px center;
    background-repeat: no-repeat;

  }

  .chart-label .medium {
    font-size: 10px
  }

  .card-legend .small {
    font-size: 11px !important;
  }

  .card-title.info {
    margin-top: 59px;
  }

  .card-title.center.info {
    margin-top: 134px;
  }

  .card-title.info, .card-title.info b, .card-legend.info {
    font-size: 15px;
  }
</style>
